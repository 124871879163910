import React, { useState } from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

// Images
import ArrowRight from 'img/arrowright.inline.svg'
import NavLink from 'components/shared/NavLink'
import { motion } from 'framer-motion'

const Usp = styled.div`
  position: relative;
  border: 2px solid ${({ theme }) => theme.color.secondaryDark};
  border-radius: 68px;
  padding: 1.6rem;
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.quote};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: 30px;
    @media (max-width: 1399px) {
      font-size: ${({ theme }) => theme.font.size.large} !important;
    }
    @media (max-width: 1199px) {
      font-size: ${({ theme }) => theme.font.size.medium} !important;
    }
    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.post} !important;
      line-height: 20px !important;
    }
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.medium} !important;
    }
  }
`

interface UspWithCurvedBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usp
}

const UspWithCurvedBlocks: React.FC<UspWithCurvedBlocksProps> = ({
  fields,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const arrowAnimations = {
    open: {
      transform: 'rotate(90deg)',
    },
    exit: {
      transform: 'rotate(0deg)',
    },
  }

  return (
    <section className="my-5 pb-5">
      <div className="container">
        <div className="row justify-content-center w-100">
          <Title content={fields.description} className="pb-5" />
          {fields.usp?.map((usp, index) => {
            const isHover = currentIndex === index

            return (
              <div
                className="col-lg-4 mb-5 mb-lg-0"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onMouseEnter={() => setCurrentIndex(index)}
                onMouseLeave={() => setCurrentIndex(null)}
              >
                <NavLink to={usp?.link?.url || '#'}>
                  <Usp className="d-flex align-items-center justify-content-center justify-content-lg-end">
                    <Content content={usp?.description} className="me-5 pe-4" />
                    <motion.div
                      initial="exit"
                      animate={isHover ? 'open' : 'exit'}
                      exit="exit"
                      variants={arrowAnimations}
                    >
                      <ArrowRight />
                    </motion.div>
                  </Usp>
                </NavLink>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default UspWithCurvedBlocks
