import React from 'react'

// Components

import UspWithPopup from 'components/flex/Usp/UspWithPopup'
import UspWithCurvedBlocks from 'components/flex/Usp/UspWithCurvedBlocks'

interface UspShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Usp
  location?: any
}

interface BannersProps {
  [key: string]: any
}

const UspShell: React.FC<UspShellProps> = ({ fields, location = {} }) => {
  const banners: BannersProps = {
    withpopup: UspWithPopup,
    withcurvedblocks: UspWithCurvedBlocks,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default UspShell
